@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.react-date-picker__calendar abbr {
    text-decoration: none;
    font-size: 10px;
}

.react-calendar__tile.react-calendar__month-view__days__day abbr {
    font-size: 12px;
}

.react-date-picker__calendar > .react-calendar {
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(144, 177, 225, 0.38);
}

.react-calendar__navigation__label__labelText {
    text-transform: capitalize;
    font-size: 13px;
}

.react-calendar__tile.react-calendar__year-view__months__month {
    text-transform: capitalize;
}
.react-date-picker__clear-button.react-date-picker__button {
    display: none;
}

.react-date-picker {
    width: 100%;
}

.react-date-picker__wrapper {
    display: flex;
    width: 100%;
    padding: 0 0.85rem;
    border: 1px solid var(--color-input-border-default) !important;
    border-radius: 0.25rem;
    transition: all 200ms ease-out;
    -webkit-transition: all 200ms ease-out;
    font-size: var(--input-size);
    min-height: 37.2px;
}

.filled-date-picker-container.form-group > div > label {
    font-weight: 500;
}

.filled-date-picker-container .react-date-picker__wrapper {
    background-color: white;
}

.react-date-picker--disabled .react-date-picker__wrapper {
    background: rgba(215, 215, 215, 0.5);
    cursor: not-allowed;
}

.filled-date-picker-container.flex {
    margin-bottom: 0;
}

.filled-date-picker-container.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filled-date-picker-container.flex > div {
    flex: 1 1;
    margin-left: 2rem;
}

.filled .react-date-picker__wrapper {
    background-color: white;
}

.filled.flex {
    margin-bottom: 0;
}

.filled.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filled.flex > div {
    flex: 1 1;
    margin-left: 2rem;
}

* {
    list-style: none;
    text-decoration: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --color-error: #dd2c00;
}

.text-color-primary {
    color: var(--color-primary);
}

.font-weight-md {
    font-weight: 500;
}

/* Sidebar */
@keyframes expandSidebar {
    from {
        width: 0px;
    }
    to {
        width: 240px;
    }
}

@keyframes shrinkSidebar {
    from {
        width: 240px;
    }
    to {
        width: 0px;
    }
}

.sidebar-expanded {
    animation: expandSidebar 500ms forwards;
}

.sidebar-shrinked {
    animation: shrinkSidebar 500ms forwards;
}

/* Sidebar menu labels */
@keyframes hideLabel {
    to {
        width: 0;
        height: 0;
    }
}

.label-hidden {
    animation: hideLabel 150ms forwards;
    animation-delay: 200ms;
}

.label-visible {
    display: block;
}

.inner-label-hidden {
    position: relative;
}

.inner-label-hidden label:first-child {
    position: absolute;
    z-index: -1;
}

/* Main area */
@keyframes expandMain {
    from {
        margin-left: 240px;
    }
    to {
        margin-left: 0px;
    }
}

@keyframes shrinkMain {
    from {
        margin-left: 0px;
    }
    to {
        margin-left: 240px;
    }
}

.main-expanded {
    animation: shrinkMain 500ms forwards;
}

.main-shrinked {
    animation: expandMain 500ms forwards;
}

/* Toggle button */
@keyframes slideExpand {
    from {
        left: 240px;
    }
    to {
        left: 0px;
    }
}

@keyframes slideShrink {
    from {
        left: 0px;
    }
    to {
        left: 240px;
    }
}

.toggler-expanded {
    animation: slideShrink 500ms forwards;
}

.toggler-shrinked {
    animation: slideExpand 500ms forwards;
}

/* Quotation Tables */
.operations > span:not(:first-child) {
    margin-top: 0.25rem;
}

.flex-card {
    display: flex;
    flex-direction: column;
}

.dossier-cee-name {
    color: #90b1e1 !important;
    font-weight: 600 !important;
    font-size: 15px !important;
}
.action-button-product-details {
    margin-right: 5px !important;
}

main > table > tbody > tr > td {
    position: relative !important;
}

.react-calendar__month-view__days__day--weekend {
    color: inherit !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: inherit !important;
}

.react-calendar__tile:disabled {
    background-color: transparent !important;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled {
    color: #c8c8c8 !important;
}

.react-calendar__month-view__days__day--weekend:disabled {
    color: #c8c8c8 !important;
}
.react-calendar__tile.react-calendar__month-view__days__day abbr {
    font-weight: 500 !important;
}

.react-calendar__tile:disabled {
    color: #c8c8c8 !important;
}

.editable-text {
    white-space: pre-wrap;
    word-break: break-word;
}

.rpv-core-text-layer {
    display: none;
}

.operation-product-input {
    margin-top: 1.5rem;
}

.operation-product-input > label {
    font-weight: 600;
    color: black !important;
}

input[type='checkbox']:disabled + label > span {
    cursor: not-allowed;
    color: #74798c;
}

.advance-invoice-checkbox-comment {
    display: flex;
    margin-left: 1.6rem;
    font-style: italic;
    color: #74798c;
    align-items: center;
}

.advance-invoice-checkbox-comment > *:first-child {
    display: inline-block;
    margin-right: 0.25rem;
}

.alert {
    margin-top: 0.75rem;
    white-space: pre-wrap;
    font-size: 12px;
}

.select-role div > header {
    min-width: 130px;
}

.select-role div {
    transition: background-color 200ms ease-in-out;
}

.select-role div.options-visible {
    background-color: var(--color-input-border-default);
    border-radius: 0.25rem;
}

.select-role div.options-visible > section {
    left: -1px;
    right: -100px;
    top: 110%;
    border-top: 1px solid var(--color-input-border-default);
    border-top-right-radius: var(--dimen-input-border-radius);
    border-radius: var(--dimen-input-border-radius);
}

.apartment-number-form {
    display: flex;
    align-items: flex-end;
    margin-bottom: 0.5rem;
}

.apartment-number-form > div:first-child {
    margin-right: 1rem;
}

@keyframes bounce {
    33% {
        transform: translateX(0.5rem);
    }

    66% {
        transform: translateX(-1rem);
    }

    100% {
        transform: translateX(0.5rem);
    }
}

.apartment-number-form > .input-error > input[type='text'] {
    border-color: red;
}

.apartment-number-form > .input-error.bounce > input[type='text'] {
    animation: bounce 200ms;
}

.quotation-contract span[role='alert'] {
    display: inline-block;
    margin-top: 0.5rem;
    font-style: normal;
    color: var(--color-error);
}

/* Antd Select */
.ant-select-dropdown {
    box-shadow: 0 2px 8px 0 rgba(16, 35, 57, 0.1) !important;
}

